import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'amfe-flexible/index.js';
import 'reset-css';
// vant
import { Loading, Toast, Icon, DatetimePicker, Field, DropdownMenu, DropdownItem, Checkbox, CheckboxGroup, Picker, Popup, Uploader, Dialog, Empty, PullRefresh, Image as VanImage, ImagePreview, CountDown, Notify, Overlay, Progress } from 'vant';
Vue.use(Loading).use(Toast).use(Icon).use(DatetimePicker).use(Field).use(DropdownItem).use(DropdownMenu).use(Checkbox).use(CheckboxGroup).use(Picker).use(Popup).use(Uploader).use(Dialog).use(Empty).use(PullRefresh).use(VanImage).use(ImagePreview).use(CountDown).use(Notify).use(Overlay).use(Progress);
Toast.setDefaultOptions({ duration: 2000 });
Vue.config.productionTip = false

// import VConsole from 'vconsole';
// const vConsole = new VConsole();
// export default vConsole;

// 正式环境清除所有console.log
if (process.env.NODE_ENV === 'production') {
  if (window) {
    window.console.log = function () {};
  }
}
// 高德
import GaodeMap from 'vue-amap';
Vue.use(GaodeMap);
// 6a12626a345134cf1ace24b485667dda
GaodeMap.initAMapApiLoader({
	key: 'ac4dd76a67a7b79f27da67ac4951c481',
  plugin: ['AMap.Geolocation']
});
// 复制
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
// 签名
import vueEsign from 'vue-esign'
Vue.use(vueEsign)

// 全局方法
import common from './utils/common.js';

// 全局过滤器
import * as filters from './utils/filters.js'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])//插入过滤器名和对应方法
})


router.beforeEach((to,from,next) => {
  window.document.title = to.meta.title;
  next();
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
