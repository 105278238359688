import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: Home,
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue'),
    meta: { title: '首页' },
  },
  {
    path: '/banma',
    name: 'banma',
    component: () => import(/* webpackChunkName: "banma" */ '@/views/link/index.vue'),
    meta: { title: '' },
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import(/* webpackChunkName: "activity" */ '@/views/activity/index.vue'),
    meta: { title: '推广活动' },
  },
  {
    path: '/poster',
    name: 'poster',
    component: () => import(/* webpackChunkName: "poster" */ '@/views/activity/poster.vue'),
    meta: { title: '推广海报' },
  },
  {
    path: '/sharePoster',
    name: 'sharePoster',
    component: () => import(/* webpackChunkName: "sharePoster" */ '@/views/activity/sharePoster.vue'),
    meta: { title: '分享海报' },
  },
  {
    path: '/withdrawal',
    name: 'withdrawal',
    component: () => import(/* webpackChunkName: "withdrawal" */ '@/views/activity/withdrawal.vue'),
    meta: { title: '提现' },
  },
  {
    path: '/rescue',
    name: 'rescue',
    component: () => import(/* webpackChunkName: "rescue" */ '@/views/rescue/index.vue'),
    meta: { title: '', keepAlive: true },
  },
  {
    path: '/location',
    name: 'location',
    component: () => import(/* webpackChunkName: "location" */ '@/views/rescue/location.vue'),
    meta: { title: '选择地点' },
  }, 
  {
    path: '/surveyor',
    name: 'surveyor',
    component: () => import(/* webpackChunkName: "surveyor" */ '@/views/rescue/surveyor.vue'),
    meta: { title: '查勘员' },
  }, 
  {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "order" */ '@/views/rescue/order.vue'),
    meta: { title: '' },
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import(/* webpackChunkName: "orderDetail" */ '@/views/rescue/orderDetail.vue'),
    meta: { title: '订单详情' },
  },
  {
    path: '/orderPhoto',
    name: 'orderPhoto',
    component: () => import(/* webpackChunkName: "orderPhoto" */ '@/views/rescue/orderPhoto.vue'),
    meta: { title: '案件照片' },
  },
  {
    path: '/owner_order',
    name: 'owner_order',
    component: () => import(/* webpackChunkName: "owner_order" */ '@/views/checkList/owner_order.vue'),
    meta: { title: '' },
  },
  {
    path: '/signFile',
    name: 'signFile',
    component: () => import(/* webpackChunkName: "signFile" */ '@/views/checkList/signFile.vue'),
    meta: { title: '免责协议' },
  },
  {
    path: '/driver',
    name: 'driver',
    component: () => import(/* webpackChunkName: "driver" */ '@/views/checkList/driver.vue'),
    meta: { title: '' },
  },
  {
    path: '/order_info',
    name: 'order_info',
    component: () => import(/* webpackChunkName: "order_info" */ '@/views/checkList/order_info.vue'),
    meta: { title: '' },
  },
  {
    path: '/app',
    name: 'app',
    component: () => import(/* webpackChunkName: "nft" */ '@/views/app/version.vue'),
    meta: { title: '' },
  },
  // {
  //   path: '/agreement',
  //   name: 'agreement',
  //   component: () => import(/* webpackChunkName: "nft" */ '@/views/app/agreement.vue'),
  //   meta: { title: '' },
  // },
  {
    path: '/compressImg',
    name: 'compressImg',
    component: () => import(/* webpackChunkName: "driver" */ '@/views/checkList/compressImg.vue'),
    meta: { title: '' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
