const IMG_API = 'https://www.tc-rescue.com/images/tc_app/';
let validStart = {
	picture: IMG_API + 'upload_tips/mancar.png',
	name: '救援现场(车主与故障车合影)',
	key: 'mancar'
};
let validEnd = {
	picture: IMG_API + 'upload_tips/destination.png',
	name: '目的地门头照(含车牌)',
	key: 'destination'
};
// 空驶
let emptyImg = [{
	key: 'roidsign',
	picture: IMG_API + 'upload_tips/empty.png',
	name: '路牌照片'
},
{
	key: 'emptyaddress',
	picture: IMG_API + 'upload_tips/empty_field.png',
	name: '放空地点(含车牌、放空场景)'
}];

/*
    picture: 示例图；   name: 名称；   key： 标识；    step: 1 救援地, 2 目的地；  
    is_need： 是否必传   is_back: 回传上游    back_key: 上游编码    remark: 拍照要求
*/ 
let mancar = {
    picture: IMG_API + 'upload_tips/mancar.png',
    name: '救援现场',
    key: 'mancar',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let headstock = {
    picture: IMG_API + 'upload_tips/headstock.png',
    name: '车头 45°',
    key: 'headstock',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let tailstock = {
    picture: IMG_API + 'upload_tips/tailstock.png',
    name: '车尾 45°',
    key: 'tailstock',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let vin = {
    picture: IMG_API + 'upload_tips/vin.png',
    name: '车架号',
    key: 'vin',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let driving_license = {
    picture: IMG_API + 'upload_tips/driving_license.png',
    name: '行驶证照片',
    key: 'driving_license',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let driver_scene = {
    picture: IMG_API + 'upload_tips/driver_scene.png',
    name: '师傅与车合影',
    key: 'driver_scene',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let PAICWX = {
    picture: IMG_API + 'upload_tips/PAICWX.png',
    name: '平安加官微',
    key: 'PAICWX',
    step: 1,
    is_need: 0,
    is_back: 0,
    back_key: '',
    remark: ''
}
let dashboard_trailer = {
    picture: IMG_API + 'upload_tips/dashboard.png',
    name: '仪表盘',
    key: 'dashboard_trailer',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let upper_plate = {
    picture: IMG_API + 'upload_tips/upper_plate.png',
    name: '上板照片',
    key: 'upper_plate',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let trailcar = {
    picture: IMG_API + 'upload_tips/trailcar.png',
    name: '背车照',
    key: 'trailcar',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
// 搭电
let dashboard_start = {
    picture: IMG_API + 'upload_tips/dashboard_start.png',
    name: '搭电前仪表盘',
    key: 'dashboard_start',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let tester = {
    picture: IMG_API + 'upload_tips/tester_s.png',
    name: '搭电前检测仪',
    key: 'tester',
    step: 1,
    is_need: 0,
    is_back: 1,
    back_key: '',
    remark: ''
}
let polarity = {
    picture: IMG_API + 'upload_tips/ele_polarity.png',
    name: '正负极近照',
    key: 'polarity',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let process = {
    picture: IMG_API + 'upload_tips/ele_course.png',
    name: '搭电过程',
    key: 'process',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let tester_e = {
    picture: IMG_API + 'upload_tips/tester_e.png',
    name: '搭电后检测仪',
    key: 'tester_e',
    step: 1,
    is_need: 0,
    is_back: 1,
    back_key: '',
    remark: ''
}
let dashboard = {
    picture: IMG_API + 'upload_tips/dashboard.png',
    name: '搭电后仪表盘',
    key: 'dashboard',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
// 换胎
let tire_s = {
    picture: IMG_API + 'upload_tips/former_s.png',				
    name: '换胎前',
    key: 'tire_s',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let unload_tire = {
    picture: IMG_API + 'upload_tips/unload_tire.png',				
    name: '卸胎照片',
    key: 'unload_tire',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let tire_e = {
    picture: IMG_API + 'upload_tips/former_e.png',		
    name: '换胎完成',
    key: 'tire_e',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
// 困境
let environment = {
    picture: IMG_API + 'upload_tips/environment.png',
    name: '施救环境',
    key: 'environment',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let dilemma_s = {
    picture: IMG_API + 'upload_tips/dilemma_s.png',
    name: '困境救援过程',
    key: 'dilemma_s',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let dilemma_e = {
    picture: IMG_API + 'upload_tips/dilemma_e.png',
    name: '困境救援成功',
    key: 'dilemma_e',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
// 加水 
let water_p = {
    picture: IMG_API + 'upload_tips/waterOroil.png',		//紧急送水/油					
    name: '送水救援过程',
    key: 'water_p',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
// 送油
let oil_p = {
    picture: IMG_API + 'upload_tips/waterOroil.png',		//紧急送水/油					
    name: '送油救援过程',
    key: 'oil_p',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
// 加水 送油
let dashboard_speed = {
    picture: IMG_API + 'upload_tips/dashboard_speed.png',		//紧急送水/油					
    name: '仪表盘转速',
    key: 'dashboard_speed',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
// 现场抢修
let repair_s = {
    picture: IMG_API + 'upload_tips/former_s.png',					
    name: '抢修前',
    key: 'repair_s',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let repair_e = {
    picture: IMG_API + 'upload_tips/former_e.png',					
    name: '抢修完成',
    key: 'repair_e',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
// 充气
let air_s = {
    picture: IMG_API + 'upload_tips/air.png',
    name: '充气前',
    key: 'air_s',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let air_p = {
    picture: IMG_API + 'upload_tips/air_process.png',
    name: '充气过程',
    key: 'air_p',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let air_e = {
    picture: IMG_API + 'upload_tips/air_e.png',
    name: '充气完成',
    key: 'air_e',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
// 吊车
let crane_s = {
    picture: IMG_API + 'upload_tips/dilemma_s.png',
    name: '吊车救援过程',
    key: 'crane_s',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let crane_e = {
    picture: IMG_API + 'upload_tips/dilemma_e.png',
    name: '吊车救援成功',
    key: 'crane_e',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
// 充电
let charge_s = {
    picture: IMG_API + 'upload_tips/charge_s.png',
    name: '充电前电量',
    key: 'charge_s',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let charge_p = {
    picture: IMG_API + 'upload_tips/charge_c.png',
    name: '充电过程',
    key: 'charge_p',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let charge_e = {
    picture: IMG_API + 'upload_tips/charge_e.png',
    name: '充电完成电量',
    key: 'charge_e',
    step: 1,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}

// 目的地
let destination = {
    picture: IMG_API + 'upload_tips/destination.png',
    name: '目的地门头照',
    key: 'destination',
    step: 2,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let locus = {
    picture: IMG_API + 'upload_tips/locus.png',
    name: '超里程行驶轨迹',
    key: 'locus',
    step: 2,
    is_need: 0,
    is_back: 0,
    back_key: '',
    remark: ''
}
let lower_plate = {
    picture: IMG_API + 'upload_tips/lower_plate.png',
    name: '下板照片',
    key: 'lower_plate',
    step: 2,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let unload = {
    picture: IMG_API + 'upload_tips/unload_e.png',
    name: '卸车完成',
    key: 'unload',
    step: 2,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let cash_collection = {
    picture: IMG_API + 'upload_tips/cash_collection.png',
    name: '收取客户现金凭证',
    key: 'cash_collection',
    step: 2,
    is_need: 0,
    is_back: 0,
    back_key: '',
    remark: ''
}
let nps = {
    picture: IMG_API + 'upload_tips/npsTip.png',
    name: 'NPS评价',
    key: 'nps',
    step: 2,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let roidsign = {
    picture: IMG_API + 'upload_tips/empty.png',
    name: '路牌照片',
    key: 'roidsign',
    step: 3,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let emptyaddress = {
    picture: IMG_API + 'upload_tips/empty_field.png',
    name: '放空地点',
    key: 'emptyaddress',
    step: 3,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let owner_sign = {
    picture: '',
    name: '车主签名',
    key: 'owner_sign',
    step: 4,
    is_need: 1,
    is_back: 0,
    back_key: '',
    remark: ''
}
let meet_sign = {
    picture: '',
    name: '接车人签名',
    key: 'meet_sign',
    step: 4,
    is_need: 1,
    is_back: 0,
    back_key: '',
    remark: ''
}
let driver_sign = {
    picture: '',
    name: '司机签名',
    key: 'driver_sign',
    step: 4,
    is_need: 1,
    is_back: 0,
    back_key: '',
    remark: ''
}
let eroadwork = {
    picture: '',
    name: '电子工单',
    key: 'eroadwork',
    step: 4,
    is_need: 1,
    is_back: 1,
    back_key: '',
    remark: ''
}
let other = {
    picture: '',
    name: '其它照片',
    key: 'other',
    step: 4,
    is_need: 0,
    is_back: 1,
    back_key: '',
    remark: ''
}
let photoConfigData = [
    mancar, headstock, tailstock, vin, driving_license, driver_scene, PAICWX, dashboard_trailer, upper_plate, trailcar, 
    dashboard_start, tester, polarity, process, tester_e, dashboard,
    tire_s, unload_tire, tire_e,
    environment, dilemma_s, dilemma_e,
    water_p, oil_p, dashboard_speed,
    repair_s, repair_e,
    air_s, air_p, air_e,
    crane_s, crane_e,
    charge_s, charge_p, charge_e,
    destination, locus, lower_plate, unload, cash_collection, nps,
]
// 救援类型照片模板
let rescueImg = [
    {
        // 拖车
        type: 1,
        data: [ mancar, headstock, tailstock, vin, driving_license, driver_scene, PAICWX, 
        dashboard_trailer, upper_plate, trailcar, destination, locus, lower_plate, unload, cash_collection, nps,
        roidsign, emptyaddress, owner_sign, meet_sign, driver_sign, eroadwork, other ]
    },
    {
        // 轿运
        type: 11,
        data: [ mancar, headstock, tailstock, vin, driving_license, driver_scene, PAICWX,
        dashboard_trailer, upper_plate, trailcar, destination, locus, lower_plate, unload, cash_collection, nps,
        roidsign, emptyaddress, owner_sign, meet_sign, driver_sign, eroadwork, other ]
    },
    {
        // 搭电
        type: 2,
        data: [ mancar, headstock, tailstock, vin, driving_license, driver_scene, PAICWX, 
        dashboard_start, tester, polarity, process, tester_e, dashboard, nps,
        roidsign, emptyaddress, owner_sign, driver_sign, eroadwork, other ]
    },
    {
        // 换胎
        type: 3,
        data: [ mancar, headstock, tailstock, vin, driving_license, driver_scene, PAICWX, 
        tire_s, unload_tire, tire_e, nps,
        roidsign, emptyaddress, owner_sign, driver_sign, eroadwork, other ]
    },
    {
        // 困境
        type: 4,
        data: [ mancar, headstock, tailstock, vin, driving_license, driver_scene, PAICWX, 
        environment, dilemma_s, dilemma_e, nps,
        roidsign, emptyaddress, owner_sign, driver_sign, eroadwork, other ]
    },
    {
        // 加水
        type: 6,
        data: [ mancar, headstock, tailstock, vin, driving_license, driver_scene, PAICWX, 
        water_p, dashboard_speed, nps,
        roidsign, emptyaddress, owner_sign, driver_sign, eroadwork, other ]
    },
    {
        // 快修
        type: 7,
        data: [ mancar, headstock, tailstock, vin, driving_license, driver_scene, PAICWX,
        repair_s, repair_e, nps,
        roidsign, emptyaddress, owner_sign, driver_sign, eroadwork, other ]
    },
    {
        // 充气
        type: 8,
        data: [ mancar, headstock, tailstock, vin, driving_license, driver_scene, PAICWX,
        air_s, air_p, air_e, nps,
        roidsign, emptyaddress, owner_sign, driver_sign, eroadwork, other ]
    },
    {
        // 吊车
        type: 10,
        data: [ mancar, headstock, tailstock, vin, driving_license, driver_scene, PAICWX, 
        environment, crane_s, crane_e, nps,
        roidsign, emptyaddress, owner_sign, driver_sign, eroadwork, other ]
    },
    {
        // 送油
        type: 12,
        data: [ mancar, headstock, tailstock, vin, driving_license, driver_scene, PAICWX,
        oil_p, dashboard_speed, nps,
        roidsign, emptyaddress, owner_sign, driver_sign, eroadwork, other ]
    },
    {
        // 充电
        type: 15,
        data: [ mancar, headstock, tailstock, vin, driving_license, driver_scene, PAICWX,
        charge_s, charge_p, charge_e, nps,
        roidsign, emptyaddress, owner_sign, driver_sign, eroadwork, other ]
    },
]

module.exports = {
    mancar,
    destination,
	rescueImg: rescueImg,
    roidsign, emptyaddress, owner_sign, meet_sign, driver_sign, eroadwork, other,
	emptyImg: emptyImg, //空驶
	validStart,
	validEnd
}
