<template>
  <div class="home">
    
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      
    };
  },
  created(){
    // window.location.href = 'weixin://dl/business/?t=6RWXa03xVXq'
  },
  mounted(){
    
  },
  methods: { 
    
  }
}
</script>
<style lang='scss' scoped>
</style>
