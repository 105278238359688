// 默认数据
module.exports = {
    // 订单状态
    orderStatus: {
        0: '未支付',
        1: '待受理',
        31: '已受理',
        2: '已接单',
        3: '开始救援',
        4: '完成救援',
        5: '已取消',
        6: '空驶',
        7: '司机取消',
        8: '支付超时取消',
        11: '联系车主',
        12: '待接单',
        22: '已接单',
        23: '前往救援地',
        24: '到达救援地',
        25: '前往目的地',
        26: '到达目的地',
        16: '附加费待支付',
        17: '附加费已支付',
        34: '超时救援',
    },
    // 救援类型
    rescueType: {
        1: '拖车',
        2: '搭电',
        3: '换胎',
        4: '困境',
        6: '加水',
        7: '快修',
        8: '充气',
        10: '吊车',
        11: '轿运',
        12: '送油',
        15: '充电',
    },
    // 汽车类型
    carType: [
        {
            value: 1,
            title: '一类车',
            tips: '客车≤7座；货车≤2吨；',
            text: '一类车（客车≤7座；货车≤2吨；）'
        },
        {
            value: 2,
            title: '二类车',
            tips: '客车8~19座；货车2t~5t，含5t；',
            text: '二类车（客车8~19座；货车2t~5t，含5t；）'
        },
        {
            value: 3,
            title: '三类车',
            tips: '客车20~39座；货车5t~10t，含10t；',
            text: '三类车（客车20~39座；货车5t~10t，含10t；）'
        },
        {
            value: 4,
            title: '四类车',
            tips: '客车≥40座；货车10t~15t，含15t；20英寸集装箱车；',
            text: '四类车（客车≥40座；货车10t~15t，含15t；20英寸集装箱车；）'
        },
        {
            value: 5,
            title: '五类车',
            tips: '货车>15t；40英寸集装箱车；',
            text: '五类车（货车>15t；40英寸集装箱车；）'
        },
    ],
    // 吊车吨位
    craneTonnageData: [
        {
            text: '8吨',
            value: 8
        },
        {
            text: '10吨',
            value: 10
        },
        {
            text: '12吨',
            value: 12
        },
        {
            text: '16吨',
            value: 16
        },
        {
            text: '25吨',
            value: 25
        },
        {
            text: '50吨',
            value: 50
        },
        {
            text: '70吨',
            value: 70
        },
        {
            text: '100吨',
            value: 100
        },
    ],
    // 默认水印配置
    configData: {
        waterStatus: 1,
        position: 'topLeft',
        textColor: '#ff0000',
        watermarkList: [
            [{
                name: '拍摄时间',
                key: 'time',
                text: '2023/01/01 12:00',
                show: true,
                position: ''
            },{
                name: '技师姓名',
                key: 'name',
                text: '张三三',
                show: true,
                position: ''
            },{
                name: '照片名称',
                key: 'imgkey',
                text: '上板照片',
                show: true,
                position: ''
            }],
            [{
                name: '拍摄地址',
                key: 'address',
                text: '广东省深圳市宝安区',
                show: true,
                position: ''
            }],
            [{
                name: '经纬度',
                key: 'lnglat',
                text: 'lng：114.034123，lat：22.536498',
                show: true,
                position: ''
            }]
        ]
    }
}
