import Data from './data';
import store from '../store'

// 汽车类型
export function filterCartype(val, type){
    let data = Data.carType;
    for(let i=0; i<data.length; i++){
        if(val == data[i].value){
            if (type == 'title'){
                return data[i].title;
            }
        }
    }
}
// 救援类型
export function filterType(val, str){
    let typeDataConfig = store.state.typeDataConfig
    let text = '';
    for(let i=0; i<typeDataConfig.length; i++){
        if(val == typeDataConfig[i].no){
            switch(str){
                case 'short':
                    text = typeDataConfig[i].short
                    break
                default:
                    text = typeDataConfig[i].title
                    break
            }
        }
    }
    return text
}

export default {
    filterCartype,
    filterType
}
